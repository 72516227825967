import { template as template_ade6eab3615e4a229ab4b7ef9f8695c6 } from "@ember/template-compiler";
const WelcomeHeader = template_ade6eab3615e4a229ab4b7ef9f8695c6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
