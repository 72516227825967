import { template as template_42deb982ed2649638eaf8ae008ec7258 } from "@ember/template-compiler";
const FKText = template_42deb982ed2649638eaf8ae008ec7258(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
